.module {
  .desktop &.mobile_only {
    display: none !important;
  }

  .mobile &.desktop_only {
    display: none !important;
  }

  display: grid;
  grid-template-columns: repeat(8, 1fr);
  width: 1024px;
  max-width: 90%;
  margin: linearSizeWidth(20, 50, 429, 1600) auto;
  // overflow-x: hidden;

  &.full-width {
    width: 100%;
    max-width: none;
  }

  &>* {
    grid-column: 1 / span 8;
  }

  @media (min-width: 429px) {
    max-width: 70%;
  }

  @media (min-width: 568px) {
    max-width: calc(100% - 20rem);
  }
}