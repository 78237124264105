#work {

  .project_list {
    position: relative;
    list-style: none;
    padding: 0;
    text-align: right;
    // margin-top: 0;
    width: calc(100% - 2em);
    max-width: calc(100% - 2em);

    li {
      margin-bottom: 2rem;

      // @media (min-width: 429px) {
      //   margin-bottom: 10rem;
      // }
      a * {
        pointer-events: none;
      }
    }

    img {
      display: block;
      width: 100%;
    }

    p {
      font-size: linearSizeWidth(12, 18, 320, 568);
      text-transform: uppercase;
      margin: 0 0 2em;
      line-height: 2em;
      text-align: left;
      width: 100%;
      display: flex;
      justify-content: space-between;

      &::after {
        content: "+";
        color: $orange;
        font-size: 1.5em;
        transform: translateY(-0.1em);
      }
    }

    a {
      display: block;
      font-size: linearSizeWidth(12, 25.5, 429, 1600);

      .size_wrapper {
        position: relative;
      }
    }

    .size_wrapper {
      img {
        filter: brightness(85%);
        transition: transform 0.5s $ease_in_out;
      }
    }
  }

  .links {
    list-style: none;
    padding: 0;

    li {
      display: flex;
      margin-bottom: 0.75em;

      a {
        font-size: 1.125em;
        color: $orange;
      }

      svg {
        width: 1.5em;
        height: 1.15em;
        font-style: 1.125em;
        margin-right: 0.5em;
        color: $orange;
      }
    }
  }

  @media (min-width: 429px) {
  }

  // landscape IPhone 5/SE
  @media (min-width: 568px) {
    .project_list {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 1em;
      grid-row-gap: 1em;
      width: 1295px;
      max-width: calc(100% - 10rem);

      li {
        grid-column: unset;
        position: relative;
        margin: 0;

        a * {
          pointer-events: none;
        }

        p {
          font-size: linearSizeWidth(12, 18, 768, 1024);
        }

        a {
          .no-touch & {
            .size_wrapper {
              img {
                filter: brightness(100%);
              }
            }

            &:hover {
              .size_wrapper {
                img {
                  transform: scale(1.075);
                  // filter: brightness(115%);
                  filter: brightness(100%);
                  animation: hoverfilter 0.5s $ease_out; // timing-function delay iteration-count direction fill-mode;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 1024px) {
    .project_list {
      grid-template-columns: repeat(3, 1fr);

      li {
        p {
          font-size: linearSizeWidth(12, 18, 1024, 1500);
        }
      }
    }
  }

  @media (min-width: 1400px) {
    .project_list {
      // grid-template-columns: repeat(4, 1fr);
    }
  }

  @media (min-width: 2200px) {
    .project_list {
      // grid-template-columns: repeat(5, 1fr);
    }
  }
}

@keyframes hoverfilter {
  0% {
    filter: brightness(100%) blur(0px);
  }

  25% {
    filter: brightness(150%) blur(2px);
  }

  100% {
    filter: brightness(100%) blur(0px);
  }
}