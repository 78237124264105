#home {
  overflow: hidden;

  #icon-oblio {
    stroke: $orange;
    stroke-width: 2;
    fill: none;
    pointer-events: none;
  }

  .rounded_button {
    cursor: pointer;
    border-radius: 2em;
    font-size: 1em;
    font-weight: $bold;
    line-height: 2em;
    padding: 0.1em 1.5em 0;
    background: transparent;
    border: solid 2px $white;
    color: $orange;
    margin-top: 1em;

    svg {
      display: inline-block;
      width: 1em;
      height: 1em;
      color: $orange;
      stroke-width: 0.65em;
      transform: translateY(0.125em) translateX(0.5em) rotate(-90deg);
      pointer-events: none;
    }
  }

  .exit_button {
    background: $orange;
    color: $black;
    border: none;
    font-size: 0.75em;
    padding-left: 1.25em;
    margin: 0;
    line-height: 2.4em;

    svg {
      color: $black;
      stroke-width: 8;
      transform: translateY(0.125em) translateX(-0.25em) rotate(90deg);
    }
  }

  .inner_titles {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: min-content;
    position: absolute;
    left: 0;
    bottom: 2em;
    text-align: center;
    opacity: 0;
    pointer-events: none;
    z-index: 1;
    transform: translateY(20%);
    transition: opacity 0.5s, transform 0.5s $ease_in_out;

    .inner_title {
      grid-column: 1 / span 1;
      grid-row: 1 / span 1;

      margin: 0 0 0.5em;

      line-height: 1em;
      font-weight: $bold;
      font-size: 2.5em;

      opacity: 0;

      color: rgba(0,0,0,0);
      // -webkit-text-stroke: 0.0125em $orange;
      opacity: 0;//0.25;
    }

    .exit_button {
      grid-column: 1 / span 1;
      grid-row: 2 / span 1;

      justify-self: center;
    }
  }

  .what_we_do & {
    #what_we_do_inner {
      // color: $white;
      -webkit-text-stroke: 0.03em $orange;
      opacity: 1;
    }
  }

  .what_weve_done & {
    #what_weve_done_inner {
      // color: $black;
      -webkit-text-stroke: 0.03em $black;
      opacity: 1;
    }
  }

  &.in_scene {
    .inner_titles {
      opacity: 1;
      transform: translateY(0%);
      transition-delay: 1s, 1s;      
    }

    .exit_button {
      pointer-events: all;
    }
  }

  #logo_wrapper {
    display: block;
    width: 13em;
    max-width: 25vw;
    justify-self: center;
    // opacity: 0;
    transition: opacity 0.5s;
    position: relative;

    &::after {
      content: "";
      display: block;
      width: 100%;
      padding-bottom: 100%;
      position: relative;
    }

    svg {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 1000%;
      height: 1000%;
      transform: translate(-50%, -50%);
    }
  }

  .options {
    position: fixed;
    top: 4em;
    left: 0;
    width: 100%;
    height: calc(100% - 4em);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr max-content 1fr;
    align-items: center;
  }

  #what_weve_done {
    .rounded_button {
      border-color: $orange;
      color: $black;

      svg {
        color: $black;
      }
    }
  }

  h1.outline_head {
    font-size: linearSizeWidth(60, 70, 320, 414);
    margin: 0;
    text-align: center;
    display: inline-block;
    padding: 0 0.1em;
  }

  &.show {
    .landing_option {
      opacity: 1;
      transition: opacity 0.5s;

      .rounded_button {
        transform: translateY(0%);
        pointer-events: all;
      }

      a {
        opacity: 1;
        pointer-events: all;
      }
    }

    #logo_wrapper {
      opacity: 1;
    }
  }

  &.hide {
  // &.in_scene {
    .landing_option {
      opacity: 0;
    }

    #logo_wrapper {
      opacity: 0;
    }

    .inner_titles {
      opacity: 0;
      transition-delay: 0s;
    }
  }

  .landing_option {
    pointer-events: none;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    position: relative;
    opacity: 0;
    // transition: opacity 0.5s;
    margin: 1em 0;

    a {
      font-size: 0.85em;
      text-decoration: underline;
      margin-top: 1.5em;
      opacity: 0;
      transition: opacity 0.5s 0.5s;
    }

    .rounded_button {
      transform: translateY(20%);
      transition: transform 0.5s $ease_in_out;
    }

    align-self: flex-start;

    &#what_we_do {
      align-self: flex-end;

      .rounded_button {
        svg {
          transform: translateY(0.125em) translateX(-0.5em) rotate(90deg);
        }
      }
    }
  }

  #project_billboards,
  #capabilities_billboards {
    list-style: none;
    padding: 0;
    margin: 0;
    color: $white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition: opacity 0.5s;
    // opacity: 0;

    $thumb_delay: 0.2s;
    $title_delay: 0s;

    &.show {
      &.delay {
        $delay: 1s;

        li {
          .plus {
            transition-delay: $delay;
          }

          &.open {
            .thumb_wrapper {
              transition-delay: $thumb_delay + $delay, $thumb_delay + $delay;
      
              .size_wrapper {
                transition-delay: $thumb_delay + $delay, 0;
              }
            }
    
            a {
              .dotted_line {
                transition-delay: $delay;
              }
    
              .title {
                transition-delay: $title_delay + $delay, $title_delay + $delay;
              }
            }
          }
        }
      }

      li {
        opacity: 1;
        pointer-events: all;

        .plus {
          transform: scale(1);

          svg {
            transform: scale(0.35) translateZ(1px) rotate(0deg);
          }
        }
  
        .no-touch &:hover {
          .thumb_wrapper {
            .size_wrapper {
              transform: scale(1.125) rotate3d(0, 1, 0, 0deg);
              transition: transform 0.3s $ease_in_out;
//
              img {
                // transform: translate(-50%, -50%) scale(1.25);
                // filter: grayscale(0);
              }
            }
          }

          .plus {
            svg {
              transform: scale(0.35) translateZ(1px) rotate(90deg);
            }
          }
        }

        &.open {
          .thumb_wrapper {
            opacity: 1;
            transform: rotate3d(0, 1, 0.5, 0deg);
            transition-delay: $thumb_delay, $thumb_delay;
    
            .size_wrapper {
              transform: scale(0.925) rotate3d(0, 1, 0, 0deg);//scale3D(0.9, 0.9, 0.9);
              transition-delay: $thumb_delay, 0;
            }
          }
  
          a {
            .dotted_line {
              opacity: 1;
            }
  
            .title {
              opacity: 1;
              transform: translate(0, 0em);
              transition-delay: $title_delay, $title_delay;
            }
          }
        }
      }
    }

    li {
      position: absolute;
      left: 0%;
      top: 0%;
      text-align: center;

      $thumbsize: 13.5em;

      &.notransition {
        * {
          transition-duration: 0s !important;
          transition-delay: 0s !important;
        }
      }

      a {
        display: flex;
        flex-flow: column;
        align-items: center;
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translate(-50%, 1.5em);

        * {
          pointer-events: none;
        }

        .dotted_line {
          display: block;
          height: 5em;
          margin-bottom: 0.7em;
          stroke: $black;
          stroke-width: 3;
          stroke-dasharray: 10 2.5 10 2.5 10 2.5 10 2.5 10 2.5 10 2.5 10 2.5 10 100;
          opacity: 0;
          transition: opacity 0.25s;
        }

        .title {
          opacity: 0;
          transform: translate(0, 2em);
          transition: opacity 0.25s, transform 0.35s $ease_out;
          text-transform: uppercase;
        }
      }

      .plus {
        width: 3em;
        height: 3em;
        background: $black;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $orange;
        font-weight: normal;
        position: relative;
        transform: scale(0);
        transition: transform 0.25s $ease_in_out;

        svg {
          stroke-width: 16;
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          transform: scale(0.35) translateZ(1px) rotate(0deg);
          transition: transform 0.25s $ease_in_out;
        }
      }

      .thumb_wrapper {
        position: relative;
        overflow: hidden;
        width: $thumbsize;
        height: $thumbsize;
        // border: solid 2px $orange;
        border-radius: 100%;
        flex-shrink: 0;
        padding: 0;
        opacity: 0;
        transition: opacity 0.25s, transform 0.45s $ease_in_out;
        transform: rotate3d(0, 1, 0.5, 90deg);

        .thumb_circle {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          stroke: $orange;
          stroke-width: 2.5;
          fill: none;
          z-index: 2;
        }

        .size_wrapper {
          position: relative;
          overflow: hidden;
          width: 100%;
          height: 100%;
          border-radius: 100%;
          flex-shrink: 0;
          padding: 0;
          background: $black;
          transition: transform 0.65s $ease_in_out, background-color 0.25s;
          transform: scale(0.9) rotate3d(0, 1, 0, 90deg);//scale3D(0.9, 0.9, 0.9);

          &::before {
            content: "";
            display: block;
            width: 100%;
            padding-bottom: 100%;
            // position: relative;
            // mix-blend-mode: screen;
            // background-color: $black;
            // z-index: 1;
          }

          &::after {
            top: 0;
            left: 0;
          }

          img {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(1);
            height: 100%;
            width: auto;
            filter: grayscale(1);
            mix-blend-mode: screen;
            opacity: 1;
            transition: opacity 0.35s, filter 0.35s $ease_in_out, transform 0.25s $ease_in_out;
          }

          .img_overlay {
            width: 100%;
            height: 100%;
            background-color: $orange;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            mix-blend-mode: multiply;
            transition: opacity 0.35s;
          }
        }
      }

      &.show {
        &>div {
          opacity: 1;
        }
      }
    }

    a {
      font-weight: $bold;
      font-size: 0.8em;
      margin: 0;
      line-height: 1em;
      color: $white;
    }

    h2 {
      color: $orange;
      font-size: 1.5rem;
      line-height: 1.25em;
    }
  }

  #capabilities_billboards {
    li {
      a {
        .plus {
          background: $white;
          color: $black;
        }
    
        .dotted_line {
          stroke: $white;
        }
    
        .title {
          color: $white;
        }
      }
    }
  }

  #project_billboards {
    .title {
      color: $black;
    }
  }

  .billboards {
    transform-style: preserve-3d;
  }

  perspective: 800px;
  #testsquare {
    width: 0.1px;
    height: 0.1px;
    background: green;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (min-width: 414px) {
    h1.outline_head {
      font-size: linearSizeWidth(70, 110, 414, 768)
    }

    .inner_titles {
      bottom: 4em;

      .inner_title {
        font-size: 3em;
      }
    }

    .landing_option {
      align-self: center;
  
      &#what_we_do {
        align-self: center;
      }
    }
  }

  @media (min-width: 768px) {
    .rounded_button {
      margin-top: 2em;
    }
  }

  @media (min-aspect-ratio: 4/5) {
    .options {
      grid-template-rows: 1fr;
      grid-template-columns: 1fr max-content 1fr;

      top: 0;
      height: 100%;

      .landing_option {
        position: relative;

        .rounded_button {
          position: absolute;
          top: calc(100% + 2em);
          margin: 0;
        }

        a {
          position: absolute;
          top: calc(100% + 6.75em);
          margin: 0;
        }
      }
    }

    h1.outline_head {
      font-size: linearSizeWidth(40, 110, 414, 1300);
    }

    #logo_wrapper {
      width: linearSizeWidth(60, 190, 414, 1300);
    }
  }
}
