.quote_module {
  display: block;
  margin: linearSizeWidth(40, 60, 429, 1600) auto linearSizeWidth(60, 80, 429, 1600);
  width: 1200px;
  max-width: calc(100% - 10rem);

  h2,
  p {
    font-weight: $light;
    grid-column: 2 / span 14;
    text-align: center;
    margin: 0;
    color: $orange;
    text-transform: uppercase;
    line-height: 1em;
  }

  h2 {
    // font-size: 6em;
    font-size: linearSizeWidth(30, 60, 429, 1200);
  }

  p {
    font-size: 1.25em;
    line-height: 2em;

    &::before {
      content: "– ";
    }
  }

  @media (min-width: 429px) {
    margin: 7.5em auto 9.375em;
  }
}