#contact {
  color: $white;

  h1 {
    color: rgba(0,0,0,0);
    -webkit-text-stroke: 0.0125em $white;
  }

  address {
    font-style: normal;
    font-size: 1.25em;
    line-height: 1.25em;

    a {
      text-decoration: none;
    }

    .address {
      margin-top: 1em;
    }
  }

  a {
    color: $white;
    text-decoration: underline;
  }
}