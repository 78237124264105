.caption_image {
  display: grid;
  grid-template-columns: 1fr !important;
  grid-template-rows: 1fr 1fr;
  grid-row-gap: 1em;

  // .size_wrapper {
  //   grid-row: 1 / span 1;
  // }

  // .image_wrapper {
  //   width: 100%;
  // }

  .caption_wrapper {
    grid-row: 2 / span 1;
  }

  h2 {
    font-size: 1.25em;
    color: $orange;
    font-weight: $bold;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    margin: 1em 0 0.5em;
  }

  p {
    margin: 0;
  }

  @media (min-width: 769px) {
    // width: 100%;
    grid-template-columns: repeat(3, 1fr) !important;
    grid-column-gap: 1em;
    grid-template-rows: auto;

    .size_wrapper,
    .caption_wrapper {
      grid-row: 1 / span 1;
    }

    // .size_wrapper {
    //   grid-column: 1 / span 5;
    // }
    .size_wrapper,
    .image_wrapper {
      grid-column: 1 / span 2;
    }

    .caption_wrapper {
      grid-column: 3 / span 1;
      display: flex;
      flex-flow: column;
      justify-content: center;
    }

    &.image_right {
      // .size_wrapper {
      //   grid-column: 4 / span 5;
      // }
      .size_wrapper,
      .image_wrapper {
        grid-column: 2 / span 2;
      }
    
      .caption_wrapper {
        text-align: right;
        grid-column: 1 / span 1;
      }
    }

    h2 {
      margin: 0 0 0.5em;
    }
  }
}