.text_module {
  position: relative;
  margin: 0 auto;

  h1:not(.outline_head),
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    // font-size: 1.625em;
    line-height: 1.5em;
    margin-top: 0;
  }

  h1:not(.outline_head),
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $orange;
    font-weight: $bold;
    margin: 1em 0 0.25em;
    text-transform: uppercase;
  }

  .outline_head {
    line-height: 0.95em;
    margin-bottom: linearSizeWidth(10, 26, 429, 1600);
  }

  &.header_module {
    padding-top: 6.25em;

    .client {
      font-size: 1.2rem;
      font-weight: $medium;
      text-transform: uppercase;
      letter-spacing: 0.15em;
    }
  }

  .arrow {
    width: 3em;
    grid-column: 8 / span 1;
  }

  @media (min-width: 429px) {

    h1 {
      font-size: linearSizeWidth(55.17, 150, 429, 1200);
    }

    .arrow {
      width: linearSizeWidth(30, 36, 429, 1600);
    }

    &.header_module {
      padding-top: linearSizeWidth(62, 162, 429, 1600);

      .client {
        // position: absolute;
        // top: linearSizeWidth(16, 90, 429, 1600);
        line-height: linearSizeWidth(44, 90, 429, 1600);
        // margin-top: 2.5rem;
        margin: 0;
        left: 0;
      }
    }
  }

  @media (min-width: 769px) {
    &>*:not(.outline_head) {
      grid-column: 1 / span 5;
    }

    &.header_module {
      padding-top: min(calc(25vh - 4em), 350px);
      padding-bottom: 5em;
    }

    &.right {
      &>*:not(.outline_head) {
        grid-column: 4 / span 5;
        text-align: right;
      }
    }

    &.center {
      &>*:not(.outline_head) {
        grid-column: 2 / span 6;
        text-align: center;
      }
    }

    .arrow {
      position: absolute;
      bottom: 0;
      right: 0;
      grid-column: unset;
    }
  }
  
  @media (min-width: 1200px) {
  }
}