.capabilities_nav {
  background-color: $black;

  h2 {
    color: $white;
    font-weight: $normal;
    margin: 0;
    text-align: right;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: right;

    li {
      a {
        color: $white;
        font-size: 1.5em;
        line-height: 1.4em;
        font-weight: $bold;
    
        &.selected {
          text-decoration: underline;
        }
      }
    }
  }
  
}