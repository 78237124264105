.gallery {

  .row {
    display: grid;
    column-gap: 1em;
    margin-bottom: 1em;
    grid-template-columns: repeat(8, 1fr);
    grid: 1 / span 8;

    &.captionimagerow {
      grid-template-columns: 1fr;
    }

    &.uniformrow {
      &.col1 {
        .size_wrapper {
          grid-column: 1 / span 8;
        }
      }
  
      &.col2 {
        .size_wrapper {
          grid-column: auto / span 4;
        }
      }
  
      &.col4 {
        .size_wrapper {
          grid-column: auto / span 2;
        }
      }

      &.col6 {
        grid-template-columns: repeat(6, 1fr);

        .size_wrapper {
          grid-column: auto / span 1;
        }
      }
    }

    &.twothirdsrow {
      .size_wrapper {
        grid-row: 1;
      }

      &.left {
        .size_wrapper:nth-child(1) {
          grid-column: 1 / span 5;
          padding-bottom: 50%;
        }
  
        .size_wrapper:nth-child(2) {
          grid-column: 6 / span 3;
          padding-bottom: 100%;
        }
      }

      .size_wrapper:nth-child(1) {
        grid-column: 1 / span 3;
        padding-bottom: 100%;
      }

      .size_wrapper:nth-child(2) {
        grid-column: 4 / span 5;
        padding-bottom: 50%;
      }
    }
  }
}