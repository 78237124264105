.playing {
  .pause_cover {
      opacity: 0;
      pointer-events: none;

      &::before {
          transform: translate(-50%, -50%) scale(0);
      }

      &::after {
          opacity: 0;
      }
  }
}

.loading {
  .pause_cover {
      opacity: 0;

      &::before {
          transform: translate(-50%, -50%) scale(0);
      }

      &::after {
          opacity: 0;
      }
  }
}

.pause_cover {
  background: $black;
  display: block;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  cursor: pointer;

  .poster {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: 50% 50%;
      opacity: 0.5;
  }

  .play_icon {
    background-color: $orange;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 12rem;
    height: 12rem;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

  svg {
    display: block;
    fill: none;
    stroke: $white;
    width: 30%;
    height: 30%;
    stroke-width: 8;
    transform: translateX(10%);
  }
}