#about {
  // color: $white;
  // min-height: 100vh;

  // h1 {
  //   font-size: 1rem;
  //   opacity: 0;
  // }

  // svg {
  //   width: 20em;
  //   height: 20em;
  //   // margin-bottom: 3.1em;
  // }

  // h2 {
  //   font-size: 1.125em;
  //   line-height: 1.25em;
  //   margin: 0;
  // }

  // p {
  //   margin: 0.5em 0;
  // }

  // em {
  //   font-style: normal;
  //   color: $white;
  // }

  .text_module {
    p {
      color: $white;
    }
  }

  .col6 {
    .size_wrapper.loaded img {
        transform: scale(0.7) translateY(0%);
        // transition: transform .5s cubic-bezier(0.3, 0.01, 0.2, 1);
    }

    @media (max-width: 768px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: 500px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .send_us_an_email {
    display: block;
    position: relative;
    width: 225px;
    background: none;
    // border: solid 2px $orange;
    // border-radius: 100%;
    margin: 6em auto;
    padding: 0;
    font-size: 1.25em;

    span {
      display: flex;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      justify-content: center;
      align-items: center;
      color: $white;
    }
    
    &::after {
      content: "";
      display: block;
      position: relative;
      width: 100%;
      padding-top: 100%;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border: solid 2px $orange;
      border-radius: 100%;
      transition: transform 0.25s $ease_in_out;
    }

    .no-touch &:hover {
      &::before {
        transform: scale(1.125);
      }
    }
  }
}