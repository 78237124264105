@import './includes/variables';

section {
  position: relative;
  width: 100%;
  transform: translateZ(2px);
}

h1.outline_head {
  color: rgba(0,0,0,0);
  -webkit-text-stroke: 0.0125em $orange;
  opacity: 0;//0.25;
  text-transform: uppercase;
  font-size: 6em;//3.448125em;
  font-weight: $bold;
  line-height: 0.95em;
  margin: 0;
  margin-left: -0.05em;
  hyphens: manual;
}

.section_inner {
  opacity: 0;
  display: block;
  min-height: 85vh;
  position: relative;
  width: 100%;
  transform: translate3d(0, 2em, 1px);

  section.show & {
    opacity: 1;
    transform: translate3d(0, 0em, 1px);
    transition: opacity 0.35s ease-in-out, transform 0.35s ease-out;
  }

  section.hide & {
    opacity: 0;
    transform: translate3d(0, 10em, 1px);
    transition: opacity 0.35s ease-in-out, transform 0.5s ease-out;
  }

  section.hide.changesection & {
    opacity: 0;
    transform: translate3d(0, -10em, 1px);
    transition: opacity 0.35s ease-in-out, transform 0.5s ease-out;
  }

  section.hide.up & {
    opacity: 0;
    transform: translate3d(0, -10em, 1px);
    transition: opacity 0.35s ease-in-out, transform 0.65s ease-in;
  }
}

#work {
  #prev_next {
    a,
    a::after,
    span {
      border-color: $orange;
    }
  }
}

#prev_next {
  display: flex;
  justify-content: center;
  margin: 6em 0;

  a * {
    pointer-events: none;
  }

  .no-touch & a:hover {
    &::after {
      transform: scale(1.125);
    }
  }

  a::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    border: solid 2px $white;
    border-radius: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.25s $ease_in_out;
    // margin: 1em;
  }

  a,
  span {
    display: block;
    width: 12rem;
    height: 12rem;
    // border: solid 2px $white;
    // border-radius: 100%;
    margin: 1em;

    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    svg {
      width: 30%;
      transform: rotate(-90deg);
      stroke-width: 3;
    }

    &.prev {
      svg {
        transform: rotate(90deg);
      }
    }
  }

  span {
    pointer-events: none;
    opacity: 0.125;
  }
}

#icon-oblio {
  transform: translateZ(1px);

  circle {
    opacity: 0;
    transform: scale(5) translateZ(1px); // translateY(20%);
    transform-origin: 50px 50px;
    transition: opacity 0.5s, transform 0.5s $ease_in_out;
  }
  
  &.showme {
    circle {
      opacity: 1;
      transform: scale(1) translateZ(1px); // translateY(0%);
      transition: opacity 0.5s, transform 0.5s $ease_in_out;
  
      $delay: 0.075;
  
      @for $i from 1 through 8 {
        &:nth-child(#{$i}) {
          transition-delay: #{$i * $delay}s;
        }
      }
  
      // &:nth-child(1) {
      //   transition-delay: 0.1s;
      // }
  
      // &:nth-child(2) {
      //   transition-delay: 0.2s;
      // }
  
      // &:nth-child(3) {
      //   transition-delay: 0.3s;
      // }
  
      // &:nth-child(4) {
      //   transition-delay: 0.4s;
      // }
  
      // &:nth-child(5) {
      //   transition-delay: 0.5s;
      // }
  
      // &:nth-child(6) {
      //   transition-delay: 0.6s;
      // }
  
      // &:nth-child(7) {
      //   transition-delay: 0.7s;
      // }
  
      // &:nth-child(8) {
      //   transition-delay: 0.8s;
      // }
    }
  }
}

@import './widgets/capabilities_nav';
@import './sections/about';
@import './sections/contact';
@import './sections/work';
@import './sections/home';
@import './sections/capabilities';
@import './widgets/modules/module';
@import './widgets/modules/gallery';
@import './widgets/modules/caption_image';
@import './widgets/modules/text_module';
@import './widgets/modules/quote';
@import './widgets/video_player';